import React, { Component } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import RingHeader from '../components/ringHeader';
import ShoppingButton from '../components/shoppingButton';
import IntroList from '../components/introList';
import IntroGrid from '../components/introGrid';
import About from '../components/about';
import colors from '../config/color';

class Homepage extends Component {
  render() {
    // const data = this.props.data;
    return (
      <Layout>
        {/* TODO: SEO */}
        <SEO title="Here Hear 聽聽" />
        <div className="content">
          <RingHeader />
          <h1>
            革命性創新情緒感測智慧戒指
            <br />
            讓你自信從容，表現更出色
          </h1>
          <div className="banner-mask"></div>
          <h4>
            智慧情緒感測戒指 <br />
            可顯示四種情緒指標 <br />
            即時呈現心電訊號 <br />
            創新結構設計可完全貼合
          </h4>
          <div className="banner-btn">
            <ShoppingButton
              btnStyle={{ color: '#ffffff', borderColor: '#ffffff' }}
              text="立即購買"
            />
          </div>
        </div>

        <IntroList />
        <IntroGrid title="媒體好評" />
        <IntroGrid title="專家推薦" bgStyle={{ backgroundColor: '#ffffff' }} />
        <About />

        <style jsx>{`
          .content {
            background-image: url('/images/happy-businessman-celebrating-victory-at-office-PV4ZPYY.png');
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;
            height: 510px;
            text-align: center;
            position: relative;
            z-index: 0;
          }

          .banner-mask {
            width: 100%;
            height: 450px;
            background-color: ${colors['black']};
            opacity: 0.3;
            position: absolute;
            top: 60px;
            z-index: -1;
          }

          h1 {
            color: ${colors['white']};
            font-family: NotoSansCJKtc-Bold;
            font-size: 24px;
            line-height: 1.33;
            margin-top: 130px;
          }

          h4 {
            color: ${colors['white']};
            font-family: NotoSansCJKtc-Regular;
            font-size: 16px;
            line-height: 1.56;
            margin-top: 26px;
          }

          .banner-btn {
            margin-top: 26px;
          }

  


          @media (min-width: 768px) {
            .content {
              background-image: url('/images/happy-businessman-riding-bicycle-to-work-in-CQDRP6Z.png');
              background-size: cover;
              height: 600px;
            }

            .banner-mask {
              height: 540px;
            }

            h1 {
              font-size: 48px;
              line-height: 60px;
              margin: 100px 0 46px 0;
            }
        `}</style>
      </Layout>
    );
  }
}

export default Homepage;

export const pageQuery = {};
