import React from 'react';
import IntroCard from '../components/introCard';

const IntroList = () => {
  return (
    <div className="content">
      <IntroCard
        title="為什麼你需要關注自己的情緒？"
        text1="情緒不僅會影響我們的⾝體健康，也會影響我們的⼼理狀態，甚⾄是各⽅⾯的表現。"
        text2="了解⾃⼰的情緒，並且做⾃⼰情緒的主⼈，讓你更專注在⾃⼰想處理的事情上，讓你的表現更加出⾊。"
        imageUri="/images/photo@3x.png"
        imagePosition="left"
      />
      <IntroCard
        title="為什麼選擇 Here Hear？"
        text1="「Here Hear Ring (聽聽戒指)」 專注於情緒與科技的結合，可以檢測疲勞指數、憂鬱指數、緊張指數、 恐懼指數，可以幫助你察覺⽣活中的壓⼒源，讓你更了解⾃⼰的情緒。"
        text2="搭配「Here Hear APP (聽聽)」內建的助眠、放鬆、影⽚、⽂章來紓解壓⼒，讓⾃⼰達到平靜放鬆的狀態。我們與情緒科技權威及⼼理專家合作，訓練出的獨家 AI 情緒分析技術，會依照性別、年齡、測量的特徵，⾃動推薦你適合的情緒健康改善⽅案，直接對症下藥，改善你的睡眠品質、甚⾄是焦慮、緊張、憂鬱狀態，達到適度的放鬆。"
        imageUri="/images/photo_02@3x.png"
        imagePosition="right"
      />
      <IntroCard
        title="Here Hear 背後的運作原理？"
        text1="「Here Hear Ring (聽聽戒指)」是⼀款能夠測量出⼼律變異指數 (HRV) 的智慧情緒偵測戒指，科學上已臨床證明⼼律變異指數與情緒及憂鬱程度有相關性，我們與情緒科技研究權威合作，運⽤量測的 PPG 及 ECG，得知您的情緒狀態，是全球第⼀款專注於情緒偵測的智慧型穿戴裝置。"
        text2="「Here Hear Ring (聽聽戒指)」搭配「Here Hear APP (聽聽)」使⽤，可運⽤ APP 裡的 AI 情緒分析技術與⼤數據交叉⽐對，推薦您當下可以操作有效舒緩情緒的⽅式，讓您在最短的時間內掌握情緒。"
        imageUri="/images/graphic@3x.png"
        imagePosition="left"
      />
      <IntroCard
        title="四大指標，更了解情緒健康"
        text1="「以四⼤情緒指標幫助你了解情緒：憂鬱指標、壓⼒指標、焦慮指標、恐懼指標。"
        text2="同時運用 AI 偵測及⼤數據⽐對，更可以針對特定的情緒推薦個⼈化專屬舒緩活動，讓你有效⾯對情緒、掌握情緒，不論⼯作或⽇常，表現更上⼀層樓。情緒科技，讓你更專注在⽣活。"
        imageUri="/images/graphic_02@3x.png"
        imagePosition="right"
      />
      <IntroCard
        title="全球⾸創呈現 PPG 及 ECG，讓你全⽅位了解情緒"
        text1="⽬前歐美市場所⾒的智慧戒指，以量測 PPG 為主，PPG僅顯⽰情緒趨勢，無法得到準確量測當下狀態，Here Hear Ring(聽聽戒指)全球⾸創可以同時量測 PPG 及 ECG，讓你全方位了解⾃⼰的情緒。"
        imageUri="/images/graphic_03@3x.png"
        imagePosition="left"
      />
      <IntroCard
        title="⾰命性結構設計，可完全貼合"
        text1="智慧戒指在結構設計受限於戒圍密合，Here Hear Ring (聽聽戒指)開發團隊突破限制，以⾰命性機構設計成功克服戒圍限制，維持量測的精準度，讓配戴更⽅便。"
        imageUri="https://via.placeholder.com/590x360"
        imagePosition="right"
      />

      <style jsx>{`
        @media (min-width: 768px) {
        }
        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

IntroList.propTypes = {};

IntroList.defaultProps = {};

export default IntroList;
