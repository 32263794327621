import React from 'react';
import PropTypes from 'prop-types';
import colors from '../config/color';

const About = () => {
  return (
    <div className="content">
      <div className="group">
        <a href="/about">關於我們</a>
        <a href="#">品牌故事</a>
        <a href="#">媒體專區</a>
      </div>
      <div className="group">
        <div>
          <img src="/images/facebook.png" />
          <a href="#">Facebook</a>
        </div>
        <div>
          <img src="/images/youtube.png" />
          <a href="#">Youtube</a>
        </div>
        <div>
          <img src="/images/line.png" />
          <a href="#">Line@</a>
        </div>
      </div>
      <div className="group">
        <a href="#">聯絡我們</a>
        <a href="#">客服專線</a>
        <a href="#">FAQ</a>
      </div>
      <div className="group">
        <a href="#">配送資訊</a>
        <a href="#">退換貨資訊</a>
        <a href="#">聽聽 APP</a>
      </div>

      <style jsx>{`
        .content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 42px 16px;
          background-color: black;
        }

        .group {
          display: flex;
          flex-direction: column;
        }

        a {
          color: ${colors['white']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 12px;
          text-decoration: none;
          margin: 8px 0;
        }

        img {
          margin: 4px 7px 4px 0;
          vertical-align: middle;
        }

        @media (min-width: 768px) {
          .content {
            padding: 50px 15.5%;
          }

          a {
            font-size: 18px;
            margin: 25px 0;
          }

          img {
            margin: 24px 7px 24px 0;
            vertical-align: middle;
          }
        }
      `}</style>
    </div>
  );
};

About.propTypes = {
  text: PropTypes.string,
  imageUri: PropTypes.string,
  imageAlt: PropTypes.string,
};

About.defaultProps = {
  text: '',
  imageUri: '',
  imageAlt: '',
};

export default About;
