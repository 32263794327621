import React from 'react';
import PropTypes from 'prop-types';
import ShoppingButton from './shoppingButton';
import colors from '../config/color';

const IntroCard = props => {
  const { title, text1, text2, imageUri, imageAlt, imagePosition } = props;

  return (
    <div className="content">
      <img className="img-content" alt={imageAlt} src={imageUri} />
      <div className="text-content">
        <h3>{title}</h3>
        {text1 ? <p>{text1}</p> : null}
        {text2 ? <p>{text2}</p> : null}
        <div className="shopping">
          <ShoppingButton text="立即購買" />
        </div>
      </div>

      <style jsx>{`
        .content {
          margin-bottom: 50px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          
        }

        .text-content {
          display: flex;
          flex-direction: column;
        }

        h3 {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 18px;
          line-height: 1.44;
          padding: 8px 16px;
        }

        p {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 14px;
          line-height: 1.43;
          padding: 0 16px;
        }

        .shopping {
          padding-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .img-content {
          width: 100%;
          margin-top: -1px; 
        }

        @media (min-width: 768px) {
          .content {
            margin-bottom: 0;
            flex-direction: ${
              imagePosition === 'right' ? 'row' : 'row-reverse'
            };
          }

          .text-content { 
            padding: 8px 0;
            display: flex;
            justify-content: center;
          }

          .img-content {
            width: 50%;
            object-fit: cover;
          }

          h3 {
            font-size: 32px;
            padding: 0 50px 24px 60px;
          }

          p {
            font-size: 18px;
            line-height: 1.6;
            padding: 0 50px 0 60px;
          }

          .shopping {
            padding: 24px 50px 0 60px;
            justify-content: flex-start;

          }

      `}</style>
    </div>
  );
};

IntroCard.propTypes = {
  title: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  imageUri: PropTypes.string,
  imageAlt: PropTypes.string,
  imagePosition: PropTypes.string,
};

IntroCard.defaultProps = {
  title: '',
  text1: '',
  text2: '',
  imageUri: '',
  imageAlt: '',
  imagePosition: 'right',
};

export default IntroCard;
