import React from 'react';
import PropTypes from 'prop-types';
import colors from '../config/color';

const PictureCard = props => {
  const { text, imageUri, imageAlt } = props;
  return (
    <div className="content">
      <img className="img-content" alt={imageAlt} src={imageUri} />
      <p className="text-content">{text}</p>
      <a href="#">更多詳情 ></a>

      <style jsx>{`
        img {
          width: 100%;
        }

        p {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 12px;
          line-height: 1.5;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 166px;
          margin: 10px 0;
        }

        a {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 12px;
          text-decoration: none;
        }

        @media (min-width: 768px) {
          img {
            width: 260px;
            object-fit: cover;
          }

          p {
            font-size: 16px;
            width: 260px;
            margin: 12px 0 20px 0;
          }

          a {
            font-size: 16px;
          }
        }
      `}</style>
    </div>
  );
};

PictureCard.propTypes = {
  text: PropTypes.string,
  imageUri: PropTypes.string,
  imageAlt: PropTypes.string,
};

PictureCard.defaultProps = {
  text: '',
  imageUri: '',
  imageAlt: '',
};

export default PictureCard;
