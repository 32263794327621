import React from 'react';
import PropTypes from 'prop-types';
import PictureCard from './pictureCard';
import colors from '../config/color';

const IntroGrid = props => {
  const { title, bgStyle } = props;
  return (
    <div style={bgStyle} className="content">
      <h2>{title}</h2>
      <div className="card-content">
        <div className="card-sty">
          <PictureCard
            imageUri="https://via.placeholder.com/166x94"
            text="智慧戒指在機構設計上受限於戒圍密合，Here Hear Ring (聽聽戒指)開發團隊突破限制限制限制"
          />
        </div>
        <div className="card-sty">
          <PictureCard
            imageUri="https://via.placeholder.com/166x94"
            text="智慧戒指在機構設計上受限於戒圍密合，Here Hear Ring (聽聽戒指)開發團隊突破限制限制限制"
          />
        </div>
        <div className="card-sty">
          <PictureCard
            imageUri="https://via.placeholder.com/166x94"
            text="智慧戒指在機構設計上受限於戒圍密合，Here Hear Ring (聽聽戒指)開發團隊突破限制限制限制"
          />
        </div>
        <div className="card-sty">
          <PictureCard
            imageUri="https://via.placeholder.com/166x94"
            text="智慧戒指在機構設計上受限於戒圍密合，Here Hear Ring (聽聽戒指)開發團隊突破限制限制限制"
          />
        </div>
      </div>

      <style jsx>{`
        .content {
          padding: 70px 9px;
          background-color: ${colors['slime-green']};
        }

        h2 {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 32px;
          text-align: center;
          margin-bottom: 40px;
        }

        .card-content {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        }

        .card-sty {
          margin: 6px;
        }

        @media (min-width: 768px) {
          .content {
            padding: 70px 54px;
          }

          .card-sty {
            margin: 6px;
          }
        }
      `}</style>
    </div>
  );
};

IntroGrid.propTypes = {
  title: PropTypes.string,
  bgStyle: PropTypes.object,
};

IntroGrid.defaultProps = {
  title: '',
  bgStyle: {},
};

export default IntroGrid;
